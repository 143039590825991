import type { AvatarImageProps } from '~/components/avatar'
import { Avatar, AvatarFallback, AvatarImage } from '~/components/avatar'
import { getInitials } from '~/modules/user'
import { classNames } from '~/utils/class-names'

type UserAvatarProps = AvatarImageProps & {
  name?: string
  avatar?: string
  openToWork?: boolean
}

export function UserAvatar({
  avatar,
  name,
  openToWork = false,
  className,
  ...props
}: UserAvatarProps) {
  return (
    <Avatar className={classNames('relative', className)}>
      <AvatarImage src={avatar} {...props} />
      {openToWork && <img alt="" className="absolute left-0 top-0" src="/open-to-work.png" />}
      {!!name && <AvatarFallback>{getInitials(name)}</AvatarFallback>}
    </Avatar>
  )
}
