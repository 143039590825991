import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear.js'
import isBetween from 'dayjs/plugin/isBetween.js'
import isLeapYear from 'dayjs/plugin/isLeapYear.js'
import isoWeek from 'dayjs/plugin/isoWeek.js'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js'
import localeData from 'dayjs/plugin/localeData.js'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import updateLocale from 'dayjs/plugin/updateLocale.js'
import utc from 'dayjs/plugin/utc.js'
import weekday from 'dayjs/plugin/weekday.js'
import weekOfYear from 'dayjs/plugin/weekOfYear.js'

export default function customDayjs(date?: dayjs.ConfigType, format?: string, strict?: boolean) {
  return dayjs.utc(date, format, strict)
}

export function dayjsExtendPlugins() {
  dayjs.extend(relativeTime)
  dayjs.extend(localeData)
  dayjs.extend(utc)
  dayjs.extend(localizedFormat)
  dayjs.extend(isSameOrBefore)
  dayjs.extend(isSameOrAfter)
  dayjs.extend(weekOfYear)
  dayjs.extend(isLeapYear)
  dayjs.extend(dayOfYear)
  dayjs.extend(isoWeek)
  dayjs.extend(weekday)
  dayjs.extend(isBetween)
  dayjs.extend(updateLocale)
}
